//! Common
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_SIDEBAR_OPEN = 'SET_IS_SIDEBAR_OPEN';

//! Auth
export const ADMIN_LOGIN = 'ADMIN_LOGIN';

//! Dashboard

//! Current Game
//* Fun Target Timer
export const GET_CURRENT_FTT_GAME_ID = 'GET_CURRENT_FTT_GAME_ID';
export const SET_CURRENT_FTT_GAME_ID = 'SET_CURRENT_FTT_GAME_ID';
export const GET_CURRENT_FTT_GAME_TOTAL_BETS = 'GET_CURRENT_FTT_GAME_TOTAL_BETS';
export const SET_CURRENT_FTT_GAME_TOTAL_BETS = 'SET_CURRENT_FTT_GAME_TOTAL_BETS';
export const GET_CURRENT_FTT_GAME_TIMER = 'GET_CURRENT_FTT_GAME_TIMER';
export const SET_CURRENT_FTT_GAME_TIMER = 'SET_CURRENT_FTT_GAME_TIMER';
export const CURRENT_FTT_GAME_WINNER_BY_ADMIN = 'CURRENT_FTT_GAME_WINNER_BY_ADMIN';

//* Fun Sorat Timer
export const GET_CURRENT_FST_GAME_ID = 'GET_CURRENT_FST_GAME_ID';
export const SET_CURRENT_FST_GAME_ID = 'SET_CURRENT_FST_GAME_ID';
export const GET_CURRENT_FST_GAME_TOTAL_BETS = 'GET_CURRENT_FST_GAME_TOTAL_BETS';
export const SET_CURRENT_FST_GAME_TOTAL_BETS = 'SET_CURRENT_FST_GAME_TOTAL_BETS';
export const GET_CURRENT_FST_GAME_TIMER = 'GET_CURRENT_FST_GAME_TIMER';
export const SET_CURRENT_FST_GAME_TIMER = 'SET_CURRENT_FST_GAME_TIMER';
export const CURRENT_FST_GAME_WINNER_BY_ADMIN = 'CURRENT_FST_GAME_WINNER_BY_ADMIN';

//* Prologic Timer
export const GET_CURRENT_PT_GAME_ID = 'GET_CURRENT_PT_GAME_ID';
export const SET_CURRENT_PT_GAME_ID = 'SET_CURRENT_PT_GAME_ID';
export const GET_CURRENT_PT_GAME_TOTAL_BETS = 'GET_CURRENT_PT_GAME_TOTAL_BETS';
export const SET_CURRENT_PT_GAME_TOTAL_BETS = 'SET_CURRENT_PT_GAME_TOTAL_BETS';
export const GET_CURRENT_PT_GAME_TIMER = 'GET_CURRENT_PT_GAME_TIMER';
export const SET_CURRENT_PT_GAME_TIMER = 'SET_CURRENT_PT_GAME_TIMER';
export const CURRENT_PT_GAME_WINNER_BY_ADMIN = 'CURRENT_PT_GAME_WINNER_BY_ADMIN';