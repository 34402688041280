//! Auth
export const admin_login = 'api/login';

//! Dashboard

//! Current Game
//* Fun Target Timer
export const get_current_ftt_game_id = 'api/currentGame';
export const get_current_ftt_game_total_bets = 'api/totalBets';
export const get_current_ftt_game_timer = 'api/gameTimer';
export const current_ftt_game_winner_by_admin = 'api/winnerByAdmin';

//* Fun Sorat Timer
export const get_current_fst_game_id = 'api/currentGame';
export const get_current_fst_game_total_bets = 'api/totalBets';
export const get_current_fst_game_timer = 'api/gameTimer';
export const current_fst_game_winner_by_admin = 'api/winnerByAdmin';

//* Prologic Timer
export const get_current_pt_game_id = 'api/prologic/currentGame';
export const get_current_pt_game_total_bets = 'api/prologic/totalBets';
export const get_current_pt_game_timer = 'api/prologic/gameTimer';
export const current_pt_game_winner_by_admin = 'api/prologic/winnerByAdmin';