import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./utils/private-routes";
import Login from './pages/login';

//! Dahboard
import Dashboard from "./pages/dashboard";

//! Setting 
import Blogs from "./pages/setting/blogs";
import AddBlog from "./pages/setting/blogs/add-blogs";
import AboutUs from "./pages/setting/about-us";
import TermsAndConditions from "./pages/setting/terms-and-conditions";
import PrivacyPolicy from "./pages/setting/privacy-policy";
import ChangePassword from "./pages/setting/change-password";
import Subadmin from "./pages/setting/sub-admin";
import AddSubadmin from "./pages/setting/sub-admin/add-sub-admin";

//! Current Game
import CurrentGameFunTargetTimer from "./pages/current-game/fun-target-timer";
import CurrentGameFunSoratTimer from "./pages/current-game/fun-sorat-timer";
import CurrentGamePrologicTimer from "./pages/current-game/prologic-timer";



//! Customer
import AllCustomers from "./pages/customer/Our-Customer";

//! User Team
import UserTeam from "./pages/userTeam/user-team";



//! Withdraw
import Withdraw from "./pages/withdraw-history/withdraw-history";

//! Deposit History
import Deposit from "./pages/deposit-history/deposit-history";

//! 
import FunSoratgame from "./pages/games/Fun-sorat-game";
import FunTargettimer from "./pages/games/fun-target-timmer";
import Teenpatti from "./pages/games/Teen-patti";
import Rummy from "./pages/games/Rummy";
import Aviator from "./pages/games/Aviator";
import Roulette from "./pages/games/Roulette";
import Prologic777 from "./pages/games/Prologic777";



function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />

        <Route path="/" element={<PrivateRoutes />}>
          <Route path="/admin" element={<Dashboard />} />
          <Route path="*" element={<> Not Ready</>} />

          {/* Setting */}
          <Route path="/admin/setting/blogs" element={<Blogs />} />
          <Route path="/admin/setting/blogs/add-blog" element={<AddBlog mode={'Add'} />} />
          <Route path="/admin/setting/blogs/edit-blog" element={<AddBlog mode={'Edit'} />} />

          <Route path="/admin/setting/about-us" element={<AboutUs />} />
          <Route path="/admin/setting/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/admin/setting/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/admin/setting/change-password" element={<ChangePassword />} />

          <Route path="/admin/setting/sub-admin" element={<Subadmin />} />
          <Route path="/admin/setting/add-sub-admin" element={<AddSubadmin mode={'Add'} />} />
          <Route path="/admin/setting/edit-sub-admin" element={<AddSubadmin mode={'Edit'} />} />

          {/* Current Game */}
          <Route path="/admin/current-game/fun-target-timer" element={<CurrentGameFunTargetTimer />} />
          <Route path="/admin/current-game/fun-sorat-timer" element={<CurrentGameFunSoratTimer />} />
          <Route path="/admin/current-game/prologic-timer" element={<CurrentGamePrologicTimer />} />


          {/* Game */}
          <Route path="/admin/game/fun-sorat-game" element={<FunSoratgame />} />
          <Route path="/admin/game/fun-target-timmer" element={<FunTargettimer />} />
          <Route path="/admin/game/Teen-patti" element={<Teenpatti />} />
          <Route path="/admin/game/Rummy" element={<Rummy />} />
          <Route path="/admin/game/Aviator" element={<Aviator />} />
          <Route path="/admin/game/Roulette" element={<Roulette />} />
          <Route path="/admin/game/Prologic777" element={<Prologic777 />} />

          {/* Customer */}
          <Route path="/admin/customer/Our-customer" element={<AllCustomers />} />

          {/* Customer */}
          <Route path="/admin/userTeam/user-team" element={<UserTeam />} />


          {/* Withraw history */}
          <Route path="/admin/withdraw-history/withdraw-history" element={<Withdraw />} />

          {/* Deposite History */}
          <Route path="/admin/deposit-history/deposit-history" element={<Deposit />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;